export default function InstallForm (NetteAjax) {
  NetteAjax.ext({
    prepare (settings) {
      const analyze = settings.nette
      if (!analyze || !analyze.form) return
      const e = analyze.e
      let originalData = settings.data || {}
      let data = {}

      if (analyze.isSubmit) {
        data[analyze.ui.getAttribute('name')] = analyze.ui.value || ''
      } else if (analyze.isImage) {
        const rect = analyze.ui.getBoundingClientRect()
        const offset = { left: rect.left + window.scrollX, top: rect.top + window.scrollY }
        const name = analyze.ui.getAttribute('name')
        const dataOffset = [Math.max(0, e.pageX - offset.left), Math.max(0, e.pageY - offset.top)]

        if (name.indexOf('[', 0) !== -1) { // inside a container
          data[name] = dataOffset
        } else {
          data[name + '.x'] = dataOffset[0]
          data[name + '.y'] = dataOffset[1]
        }
      }

      // https://developer.mozilla.org/en-US/docs/Web/Guide/Using_FormData_Objects#Sending_files_using_a_FormData_object
      const formMethod = analyze.form.getAttribute('method')
      if (formMethod && formMethod.toLowerCase() === 'post' && 'FormData' in window) {
        const formData = new FormData(analyze.form)
        for (const i in data) {
          formData.append(i, data[i])
        }

        if (typeof originalData !== 'string') {
          for (const i in originalData) {
            formData.append(i, originalData[i])
          }
        }

        // remove empty file inputs as these causes Safari 11 to stall
        // https://stackoverflow.com/questions/49672992/ajax-request-fails-when-sending-formdata-including-empty-file-input-in-safari
        if (formData.entries && navigator.userAgent.match(/version\/11(\.[0-9]*)? safari/i)) {
          // FormData must be polyfilled in IE 11 (https://github.com/jimmywarting/FormData)
          // for .. of loop is unsupported in IE 11 causing js exception, but it cannot be fixed by for .. in
          // because FormData.entries(), .keys() etc. returns Symbol iterator which is not iterable by for .. in loop
          // Symbol iterators is also unsupported in IE 11, so only option to fix it cross-browser is to convert iterator to array.
          const formDataKeys = formData.keys()
          const entries = []
          let iterationDone = false
          while (!iterationDone) {
            try {
              const keyItem = formDataKeys.next()
              iterationDone = keyItem.done
              if (!iterationDone) {
                entries.push([keyItem.value, formData.get(keyItem.value)])
              }
            } catch (error) {
              iterationDone = true
            }
          }

          for (const index in entries) {
            const pair = entries[index]
            if (pair[1] instanceof File && pair[1].name === '' && pair[1].size === 0) {
              formData.delete(pair[0])
            }
          }
        }

        settings.data = formData
      } else {
        if (typeof originalData !== 'string') {
          originalData = this.serialize(originalData)
        }
        data = this.serialize(data)
        settings.data = this.serialize(analyze.form) + (data ? '&' + data : '') + '&' + originalData
      }
    }
  }, {
    serialize (form) {
      let field
      const s = []
      if (typeof form === 'object') {
        if (form.nodeName === 'FORM') {
          const len = form.elements.length
          for (let i = 0; i < len; i++) {
            field = form.elements[i]
            if (field.name && !field.disabled && field.type !== 'file' && field.type !== 'reset' && field.type !== 'submit' && field.type !== 'button') {
              if (field.type === 'select-multiple') {
                for (let j = form.elements[i].options.length - 1; j >= 0; j--) {
                  if (field.options[j].selected) {
                    s.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(field.options[j].value))
                  }
                }
              } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
                s.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(field.value))
              }
            }
          }
        } else {
          for (const k in form) {
            s.push(encodeURIComponent(k) + '=' + encodeURIComponent(form[k]))
          }
        }
      }
      return s.join('&').replace(/%20/g, '+')
    }
  })
}
