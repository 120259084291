<template>
  <flickity :options="flickityOptions"  ref="slider">
    <slot></slot>
  </flickity>
</template>

<script>
export default {
  data () {
    return {
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: true,
        wrapAround: true,
        freeScroll: false,
        autoPlay: 4000
      }
    }
  },
  mounted () {
    this.$refs.slider.on('pointerUp', function () {
    })
  }
}
</script>
