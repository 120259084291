export default class Nette {
  // LiveForm: original netteForms.js code
  // this.formErrors = [];
  static version = '2.4'

  LiveForm = null

  constructor (LiveForm) {
    this.LiveForm = LiveForm
  }

  /**
   * Attaches a handler to an event for the element.
   */
  addEvent (element, on, callback) {
    if (element.addEventListener) {
      element.addEventListener(on, callback)
    } else if (on === 'DOMContentLoaded') {
      element.attachEvent('onreadystatechange', function () {
        if (element.readyState === 'complete') {
          callback.call(this)
        }
      })
    } else {
      element.attachEvent('on' + on, this.getHandler(callback))
    }
  };

  getHandler (callback) {
    return function (e) {
      return callback.call(this, e)
    }
  }

  /**
   * Returns the value of form element.
   */
  getValue (elem) {
    let i
    if (!elem) {
      return null
    } else if (!elem.tagName) { // RadioNodeList, HTMLCollection, array
      return elem[0] ? this.getValue(elem[0]) : null
    } else if (elem.type === 'radio') {
      const elements = elem.form.elements // prevents problem with name 'item' or 'namedItem'
      for (i = 0; i < elements.length; i++) {
        if (elements[i].name === elem.name && elements[i].checked) {
          return elements[i].value
        }
      }
      return null
    } else if (elem.type === 'file') {
      return elem.files || elem.value
    } else if (elem.tagName.toLowerCase() === 'select') {
      const index = elem.selectedIndex
      const options = elem.options
      const values = []

      if (elem.type === 'select-one') {
        return index < 0 ? null : options[index].value
      }

      for (i = 0; i < options.length; i++) {
        if (options[i].selected) {
          values.push(options[i].value)
        }
      }
      return values
    } else if (elem.name && elem.name.match(/\[\]$/)) { // multiple elements []
      const elements = elem.form.elements[elem.name].tagName ? [elem] : elem.form.elements[elem.name]
      const values = []

      for (i = 0; i < elements.length; i++) {
        // LiveForm: original netteForms.js code
        /* if (elements[i].type !== 'checkbox' || elements[i].checked) {
          values.push(elements[i].value);
        } */
        // LiveForm: addition
        const value = elements[i].value
        if (elements[i].type === 'checkbox' && elements[i].checked) {
          values.push(value)
        } else if (elements[i].type !== 'checkbox' && value !== '') {
          values.push(value)
        }
      }
      return values
    } else if (elem.type === 'checkbox') {
      return elem.checked
    } else if (elem.tagName.toLowerCase() === 'textarea') {
      return elem.value.replace('\r', '')
    } else {
      return elem.value.replace('\r', '').replace(/^\s+|\s+$/g, '')
    }
  };

  /**
   * Returns the effective value of form element.
   */
  getEffectiveValue (elem) {
    var val = this.getValue(elem)
    if (elem.getAttribute) {
      if (val === elem.getAttribute('data-nette-empty-value')) {
        val = ''
      }
    }
    return val
  };

  /**
   * Validates form element against given rules.
   */
  validateControl (elem, rules, onlyCheck, value, emptyOptional) {
    // LiveForm: addition
    // Fix for CheckboxList - validation rules are present always only on first input
    if (elem.name && elem.name.match(/\[\]$/) && elem.type.toLowerCase() === 'checkbox') {
      elem = elem.form.elements[elem.name].tagName ? elem : elem.form.elements[elem.name][0]
    }

    elem = elem.tagName ? elem : elem[0] // RadioNodeList
    rules = rules || this.parseJSON(elem.getAttribute('data-nette-rules'))
    value = value === undefined ? { value: this.getEffectiveValue(elem) } : value

    for (var id = 0, len = rules.length; id < len; id++) {
      var rule = rules[id]
      var op = rule.op.match(/(~)?([^?]+)/)
      var curElem = rule.control ? elem.form.elements.namedItem(rule.control) : elem

      rule.neg = op[1]
      rule.op = op[2]
      rule.condition = !!rule.rules

      if (!curElem) {
        continue
      } else if (rule.op === 'optional') {
        emptyOptional = !this.validateRule(elem, ':filled', null, value)
        continue
      } else if (emptyOptional && !rule.condition && rule.op !== ':filled') {
        continue
      }

      curElem = curElem.tagName ? curElem : curElem[0] // RadioNodeList
      var curValue = elem === curElem ? value : { value: this.getEffectiveValue(curElem) }
      var success = this.validateRule(curElem, rule.op, rule.arg, curValue)

      if (success === null) {
        continue
      } else if (rule.neg) {
        success = !success
      }

      if (rule.condition && success) {
        if (!this.validateControl(elem, rule.rules, onlyCheck, value, rule.op === ':blank' ? false : emptyOptional)) {
          return false
        }
      } else if (!rule.condition && !success) {
        if (this.isDisabled(curElem)) {
          continue
        }
        if (!onlyCheck) {
          var arr = this.isArray(rule.arg) ? rule.arg : [rule.arg]
          var message = rule.msg.replace(/%(value|\d+)/g, (foo, m) => {
            return this.getValue(m === 'value' ? curElem : elem.form.elements.namedItem(arr[m].control))
          })
          this.addError(curElem, message)
        }
        return false
      }
    }

    if (elem.type === 'number' && !elem.validity.valid) {
      if (!onlyCheck) {
        this.addError(elem, 'Please enter a valid value.')
      }
      return false
    }

    // LiveForm: addition
    if (!onlyCheck) {
      this.LiveForm.removeError(elem)
    }

    return true
  };

  /**
   * Validates whole form.
   */
  validateForm (sender, onlyCheck) {
    var form = sender.form || sender
    var scope = false

    // LiveForm: addition
    this.LiveForm.setFormProperty(form, 'hasError', false)

    // LiveForm: original netteForms.js code
    // this.formErrors = [];

    if (form['nette-submittedBy'] && form['nette-submittedBy'].getAttribute('formnovalidate') !== null) {
      var scopeArr = this.parseJSON(form['nette-submittedBy'].getAttribute('data-nette-validation-scope'))
      if (scopeArr.length) {
        scope = new RegExp('^(' + scopeArr.join('-|') + '-)')
      } else {
        // LiveForm: original netteForms.js code
        // this.showFormErrors(form, []);
        return true
      }
    }

    var radios = {}; var i; var elem
    // LiveForm: addition
    var success = true

    for (i = 0; i < form.elements.length; i++) {
      elem = form.elements[i]

      // selectize addition
      if (elem.id.substr(elem.id.length - 11, 11) === '-selectized') { continue }

      if (elem.tagName && !(elem.tagName.toLowerCase() in { input: 1, select: 1, textarea: 1, button: 1 })) {
        continue
      } else if (elem.type === 'radio') {
        if (radios[elem.name]) {
          continue
        }
        radios[elem.name] = true
      } else if (elem.type === 'submit') { // do not validate submit
        continue
      }

      if ((scope && !elem.name.replace(/]\[|\[|]|$/g, '-').match(scope)) || this.isDisabled(elem)) {
        continue
      }

      // LiveForm: addition
      success = this.validateControl(elem, null, onlyCheck) && success
      if (!success && !this.LiveForm.options.showAllErrors) {
        break
      }
      // LiveForm: original netteForms.js code
      /* if (!this.validateControl(elem, null, onlyCheck) && !this.formErrors.length) {
        return false;
      } */
    }
    // LiveForm: change
    return success

    // LiveForm: original netteForms.js code
    /* var success = !this.formErrors.length;
    this.showFormErrors(form, this.formErrors);
    return success; */
  };

  /**
   * Check if input is disabled.
   */
  isDisabled (elem) {
    if (elem.type === 'radio') {
      for (var i = 0, elements = elem.form.elements; i < elements.length; i++) {
        if (elements[i].name === elem.name && !elements[i].disabled) {
          return false
        }
      }
      return true
    }
    return elem.disabled
  };

  // LiveForm: change
  /**
   * Display error message.
   */
  addError (elem, message) {
    // LiveForm: addition
    var noLiveValidation = this.LiveForm.hasClass(elem, this.LiveForm.options.disableLiveValidationClass)
    // User explicitly disabled live-validation so we want to show simple alerts
    if (noLiveValidation) {
      // notify errors for elements with disabled live validation (but only errors and not during onLoadValidation)
      if (message && !this.LiveForm.getFormProperty(elem.form, 'hasError') && !this.LiveForm.getFormProperty(elem.form, 'onLoadValidation')) {
        alert(message)
      }
    }
    if (elem.focus && !this.LiveForm.getFormProperty(elem.form, 'hasError')) {
      if (!this.LiveForm.focusing) {
        this.LiveForm.focusing = true
        elem.focus()
        setTimeout(() => {
          this.LiveForm.focusing = false

          // Scroll by defined offset (if enabled)
          // NOTE: We use it with setTimetout because IE9 doesn't always catch instant scrollTo request
          var focusOffsetY = this.LiveForm.options.focusScreenOffsetY
          if (focusOffsetY !== false && elem.getBoundingClientRect().top < focusOffsetY) {
            window.scrollBy(0, elem.getBoundingClientRect().top - focusOffsetY)
          }
        }, 10)
      }
    }
    if (!noLiveValidation) {
      this.LiveForm.addError(elem, message)
    }
  };

  // LiveForm: original netteForms.js code
  /**
   * Adds error message to the queue.
   */
  /* addError (elem, message) {
    this.formErrors.push({
      element: elem,
      message: message
    });
  }; */

  // LiveForm: original netteForms.js code
  /**
   * Display error messages.
   */
  /* showFormErrors (form, errors) {
    var messages = [],
      focusElem;

    for (var i = 0; i < errors.length; i++) {
      var elem = errors[i].element,
        message = errors[i].message;

      if (!this.inArray(messages, message)) {
        messages.push(message);

        if (!focusElem && elem.focus) {
          focusElem = elem;
        }
      }
    }

    if (messages.length) {
      alert(messages.join('\n'));

      if (focusElem) {
        focusElem.focus();
      }
    }
  }; */

  /**
   * Expand rule argument.
   */
  expandRuleArgument (form, arg) {
    if (arg && arg.control) {
      var control = form.elements.namedItem(arg.control)
      var value = { value: this.getEffectiveValue(control) }
      this.validateControl(control, null, true, value)
      arg = value.value
    }
    return arg
  };

  /**
   * Validates single rule.
   */
  validateRule (elem, op, arg, value) {
    value = value === undefined ? { value: this.getEffectiveValue(elem) } : value

    if (op.charAt(0) === ':') {
      op = op.substr(1)
    }
    op = op.replace('::', '_')
    op = op.replace(/\\/g, '')

    var arr = this.isArray(arg) ? arg.slice(0) : [arg]
    for (var i = 0, len = arr.length; i < len; i++) {
      arr[i] = this.expandRuleArgument(elem.form, arr[i])
    }
    return this.validators[op]
      ? this.validators[op](elem, this.isArray(arg) ? arr : arr[0], value.value, value)
      : null
  };

  validators = {
    filled: (elem, arg, val) => {
      if (elem.type === 'number' && elem.validity.badInput) {
        return true
      }
      return val !== '' && val !== false && val !== null &&
        (!this.isArray(val) || !!val.length) &&
        (!window.FileList || !(val instanceof window.FileList) || val.length)
    },

    blank: (elem, arg, val) => {
      return !this.validators.filled(elem, arg, val)
    },

    valid: (elem, arg, val) => {
      return this.validateControl(elem, null, true)
    },

    equal: (elem, arg, val) => {
      if (arg === undefined) {
        return null
      }

      function toString (val) {
        if (typeof val === 'number' || typeof val === 'string') {
          return '' + val
        } else {
          return val === true ? '1' : ''
        }
      }

      val = this.isArray(val) ? val : [val]
      arg = this.isArray(arg) ? arg : [arg]
      // eslint-disable-next-line
      loop:
      for (var i1 = 0, len1 = val.length; i1 < len1; i1++) {
        for (var i2 = 0, len2 = arg.length; i2 < len2; i2++) {
          if (toString(val[i1]) === toString(arg[i2])) {
            // eslint-disable-next-line
            continue loop
          }
        }
        return false
      }
      return true
    },

    notEqual: (elem, arg, val) => {
      return arg === undefined ? null : !this.validators.equal(elem, arg, val)
    },

    minLength: (elem, arg, val) => {
      if (elem.type === 'number') {
        if (elem.validity.tooShort) {
          return false
        } else if (elem.validity.badInput) {
          return null
        }
      }
      return val.length >= arg
    },

    maxLength: (elem, arg, val) => {
      if (elem.type === 'number') {
        if (elem.validity.tooLong) {
          return false
        } else if (elem.validity.badInput) {
          return null
        }
      }
      return val.length <= arg
    },

    length: (elem, arg, val) => {
      if (elem.type === 'number') {
        if (elem.validity.tooShort || elem.validity.tooLong) {
          return false
        } else if (elem.validity.badInput) {
          return null
        }
      }
      arg = this.isArray(arg) ? arg : [arg, arg]
      return (arg[0] === null || val.length >= arg[0]) && (arg[1] === null || val.length <= arg[1])
    },

    email: (elem, arg, val) => {
      return (/^("([ !#-[\]-~]|\\[ -~])+"|[-a-z0-9!#$%&'*+/=?^_`{|}~]+(\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*)@([0-9a-z\u00C0-\u02FF\u0370-\u1EFF]([-0-9a-z\u00C0-\u02FF\u0370-\u1EFF]{0,61}[0-9a-z\u00C0-\u02FF\u0370-\u1EFF])?\.)+[a-z\u00C0-\u02FF\u0370-\u1EFF]([-0-9a-z\u00C0-\u02FF\u0370-\u1EFF]{0,17}[a-z\u00C0-\u02FF\u0370-\u1EFF])?$/i).test(val)
    },

    url (elem, arg, val, value) {
      if (!(/^[a-z\d+.-]+:/).test(val)) {
        val = 'http://' + val
      }
      if ((/^https?:\/\/((([-_0-9a-z\u00C0-\u02FF\u0370-\u1EFF]+\.)*[0-9a-z\u00C0-\u02FF\u0370-\u1EFF]([-0-9a-z\u00C0-\u02FF\u0370-\u1EFF]{0,61}[0-9a-z\u00C0-\u02FF\u0370-\u1EFF])?\.)?[a-z\u00C0-\u02FF\u0370-\u1EFF]([-0-9a-z\u00C0-\u02FF\u0370-\u1EFF]{0,17}[a-z\u00C0-\u02FF\u0370-\u1EFF])?|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\[[0-9a-f:]{3,39}\])(:\d{1,5})?(\/\S*)?$/i).test(val)) {
        value.value = val
        return true
      }
      return false
    },

    regexp: (elem, arg, val) => {
      var parts = typeof arg === 'string' ? arg.match(/^\/(.*)\/([imu]*)$/) : false
      try {
        return parts && (new RegExp(parts[1], parts[2].replace('u', ''))).test(val)
      } catch (e) {}
    },

    pattern: (elem, arg, val) => {
      if (typeof arg !== 'string') {
        return null
      }

      if (val instanceof FileList) {
        try {
          for (var i = 0; i < val.length; i++) {
            if ((new RegExp('^(?:' + arg + ')$')).test(val[i].name) === false) {
              return false
            }
          }
          return true
        } catch (e) {}
      } else {
        try {
          return (new RegExp('^(?:' + arg + ')$')).test(val)
        } catch (e) {}
      }
    },

    integer: (elem, arg, val) => {
      if (elem.type === 'number' && elem.validity.badInput) {
        return false
      }
      return (/^-?[0-9]+$/).test(val)
    },

    float (elem, arg, val, value) {
      if (elem.type === 'number' && elem.validity.badInput) {
        return false
      }
      val = val.replace(' ', '').replace(',', '.')
      if ((/^-?[0-9]*[.,]?[0-9]+$/).test(val)) {
        value.value = val
        return true
      }
      return false
    },

    min: (elem, arg, val) => {
      if (elem.type === 'number') {
        if (elem.validity.rangeUnderflow) {
          return false
        } else if (elem.validity.badInput) {
          return null
        }
      }
      return this.validators.range(elem, [arg, null], val)
    },

    max: (elem, arg, val) => {
      if (elem.type === 'number') {
        if (elem.validity.rangeOverflow) {
          return false
        } else if (elem.validity.badInput) {
          return null
        }
      }
      return this.validators.range(elem, [null, arg], val)
    },

    range: (elem, arg, val) => {
      if (elem.type === 'number') {
        if ((elem.validity.rangeUnderflow && arg[0] !== null) || (elem.validity.rangeOverflow && arg[1] !== null)) {
          return false
        } else if (elem.validity.badInput) {
          return null
        }
      }

      if (elem.type === 'date') {
        arg[0] = (arg[0] === null ? null : new Date(arg[0]).getTime())
        arg[1] = (arg[1] === null ? null : new Date(arg[1]).getTime())
        val = new Date(val).getTime()
      }

      return this.isArray(arg)
        ? ((arg[0] === null || parseFloat(val) >= arg[0]) && (arg[1] === null || parseFloat(val) <= arg[1])) : null
    },

    submitted: (elem, arg, val) => {
      return elem.form['nette-submittedBy'] === elem
    },

    fileSize: (elem, arg, val) => {
      if (window.FileList) {
        for (var i = 0; i < val.length; i++) {
          if (val[i].size > arg) {
            return false
          }
        }
      }
      return true
    },

    image: (elem, arg, val) => {
      if (window.FileList && val instanceof window.FileList) {
        for (var i = 0; i < val.length; i++) {
          var type = val[i].type
          if (type && type !== 'image/gif' && type !== 'image/png' && type !== 'image/jpeg') {
            return false
          }
        }
      }
      return true
    }
  };

  /**
   * Process all toggles in form.
   */
  toggleForm (form, elem) {
    var i
    this.toggles = {}
    for (i = 0; i < form.elements.length; i++) {
      if (form.elements[i].tagName.toLowerCase() in { input: 1, select: 1, textarea: 1, button: 1 }) {
        this.toggleControl(form.elements[i], null, null, !elem)
      }
    }

    for (i in this.toggles) {
      this.toggle(i, this.toggles[i], elem)
    }
  };

  /**
   * Process toggles on form element.
   */
  toggleControl (elem, rules, success, firsttime, value) {
    rules = rules || this.parseJSON(elem.getAttribute('data-nette-rules'))
    value = value === undefined ? { value: this.getEffectiveValue(elem) } : value

    var has = false
    var handled = []
    var handler = () => {
      this.toggleForm(elem.form, elem)
    }
    var curSuccess

    for (var id = 0, len = rules.length; id < len; id++) {
      var rule = rules[id]
      var op = rule.op.match(/(~)?([^?]+)/)
      var curElem = rule.control ? elem.form.elements.namedItem(rule.control) : elem

      if (!curElem) {
        continue
      }

      curSuccess = success
      if (success !== false) {
        rule.neg = op[1]
        rule.op = op[2]
        var curValue = elem === curElem ? value : { value: this.getEffectiveValue(curElem) }
        curSuccess = this.validateRule(curElem, rule.op, rule.arg, curValue)
        if (curSuccess === null) {
          continue
        } else if (rule.neg) {
          curSuccess = !curSuccess
        }
        if (!rule.rules) {
          success = curSuccess
        }
      }

      if ((rule.rules && this.toggleControl(elem, rule.rules, curSuccess, firsttime, value)) || rule.toggle) {
        has = true
        if (firsttime) {
          var oldIE = !document.addEventListener // IE < 9
          var name = curElem.tagName ? curElem.name : curElem[0].name
          var els = curElem.tagName ? curElem.form.elements : curElem

          for (var i = 0; i < els.length; i++) {
            if (els[i].name === name && !this.inArray(handled, els[i])) {
              this.addEvent(els[i], oldIE && els[i].type in { checkbox: 1, radio: 1 } ? 'click' : 'change', handler)
              handled.push(els[i])
            }
          }
        }
        for (var id2 in rule.toggle || []) {
          if (Object.prototype.hasOwnProperty.call(rule.toggle, id2)) {
            this.toggles[id2] = this.toggles[id2] || (rule.toggle[id2] ? curSuccess : !curSuccess)
          }
        }
      }
    }
    return has
  };

  parseJSON (s) {
    return JSON.parse(s || '[]')
  };

  /**
   * Displays or hides HTML element.
   */
  toggle (id, visible, srcElement) {
    console.log('implement toggle!', id, visible)
    /*
    var $el = $('#' + id)
    if (visible) {
      $el.slideDown()

      if ($el.is('[data-nette-rules-hidden]')) {
        $el.attr('data-nette-rules', $el.attr('data-nette-rules-hidden'))
        $el.attr('data-nette-rules-hidden', null)
      } else {
        $el.find('[data-nette-rules-hidden]').each(function () {
          var $this = $(this)
          $this.attr('data-nette-rules', $this.attr('data-nette-rules-hidden'))
          $this.attr('data-nette-rules-hidden', null)
        })
      }
    } else {
      $el.slideUp()

      if ($el.is('[data-nette-rules]')) {
        $el.attr('data-nette-rules-hidden', $el.attr('data-nette-rules'))
        $el.attr('data-nette-rules', null)
      } else {
        $el.find('[data-nette-rules]').each(function () {
          var $this = $(this)
          $this.attr('data-nette-rules-hidden', $this.attr('data-nette-rules'))
          $this.attr('data-nette-rules', null)
        })
      }
    }
    */
  };

  /**
   * Setup handlers.
   */
  initForm (form) {
    form.noValidate = 'novalidate'

    // LiveForm: addition
    this.LiveForm.forms[form.id] = {
      hasError: false,
      onLoadValidation: false
    }

    this.addEvent(form, 'submit', (e) => {
      if (!this.validateForm(form)) {
        if (e && e.stopPropagation) {
          e.stopPropagation()
          e.preventDefault()
        } else if (window.event) {
          event.cancelBubble = true
          event.returnValue = false
        }
      }
    })

    this.toggleForm(form)

    // LiveForm: addition
    for (var i = 0; i < form.elements.length; i++) {
      var elem = form.elements[i]

      // basic checks do not setup handlers on non form elements
      // selectize addition
      if (elem.id.substr(elem.id.length - 11, 11) === '-selectized') { continue }

      if (elem.tagName && !(elem.tagName.toLowerCase() in { input: 1, select: 1, textarea: 1, button: 1 })) {
        continue
      } else if (elem.type === 'submit') { // do not validate submit
        continue
      }

      this.LiveForm.setupHandlers(elem)
      this.LiveForm.processServerErrors(elem)
    }
  };

  /**
   * Determines whether the argument is an array.
   */
  isArray (arg) {
    return Object.prototype.toString.call(arg) === '[object Array]'
  };

  /**
   * Search for a specified value within an array.
   */
  inArray (arr, val) {
    if ([].indexOf) {
      return arr.indexOf(val) > -1
    } else {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] === val) {
          return true
        }
      }
      return false
    }
  };

  /**
   * Converts string to web safe characters [a-z0-9-] text.
   */
  webalize (s) {
    s = s.toLowerCase()
    var res = ''; var i; var ch
    for (i = 0; i < s.length; i++) {
      ch = this.webalizeTable[s.charAt(i)]
      res += ch || s.charAt(i)
    }
    return res.replace(/[^a-z0-9]+/g, '-').replace(/^-|-$/g, '')
  };

  webalizeTable = { \u00e1: 'a', \u00e4: 'a', \u010d: 'c', \u010f: 'd', \u00e9: 'e', \u011b: 'e', \u00ed: 'i', \u013e: 'l', \u0148: 'n', \u00f3: 'o', \u00f4: 'o', \u0159: 'r', \u0161: 's', \u0165: 't', \u00fa: 'u', \u016f: 'u', \u00fd: 'y', \u017e: 'z' };
}
