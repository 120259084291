import Cookies from 'js-cookie'

// initial state
const state = () => ({
  openPanel: Cookies.get('cookiepanel') !== 'closed'
})

// functionName: (state, getters, rootState) => {}
const getters = {

}

// functionName ({ commit, state, dispatch }, param, ...) {}
const actions = {
  openPanel ({ commit, state }) {
    commit('openPanel')
  },

  closePanel ({ commit, state }) {
    commit('closePanel')
    Cookies.set('cookiepanel', 'closed')
  }
}

// functionName (state, { param, ... }) {
const mutations = {
  openPanel (state) {
    state.openPanel = true
  },

  closePanel (state) {
    state.openPanel = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
