export default new class NetteAjaxInner {
  // self: this,
  initialized = false
  contexts = {}
  on = {
    init: {},
    load: {},
    prepare: {},
    before: {},
    start: {},
    success: {},
    complete: {},
    error: {}
  }

  async fire () {
    var result = true
    var args = Array.prototype.slice.call(arguments)
    var props = args.shift()
    var name = (typeof props === 'string') ? props : props.name
    var off = (typeof props === 'object') ? props.off || [] : []
    args.push(this)
    for (const index in this.on[name]) {
      const reaction = this.on[name][index]
      if (reaction === undefined || off.indexOf(index) !== -1) continue

      const temp = await reaction.apply(this.contexts[index], args)
      result = (temp === undefined || temp)
      if (!result) break
    }
    return result
  }

  ext (callbacks, context, name) {
    while (!name) {
      name = 'ext_' + Math.random()
      if (this.contexts[name]) {
        name = undefined
      }
    }
    for (const event in callbacks) {
      const callback = callbacks[event]
      this.on[event][name] = callback
    }
    const self = this

    this.contexts[name] = {
      ...(context || {}),
      ...{
        name: function () {
          return name
        },
        ext: function (name, force) {
          var ext = self.contexts[name]
          if (!ext && force) throw new Error("Extension '" + this.name() + "' depends on disabled extension '" + name + "'.")
          return ext
        }
      }
    }
  }
}()
