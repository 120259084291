import LiveForm from '../nette/LiveForm'
import NetteAjax from '../nette/NetteAjax/index'

export default {
  install (Vue, options) {
    Vue.directive('nette-form', {
      bind (el, binding) {
        console.log('bind', el, binding)
      },

      inserted (el, binding) {
        const form = el
        for (var j = 0; j < form.elements.length; j++) {
          if (form.elements[j].getAttribute('data-nette-rules')) {
            LiveForm.Nette.initForm(form)

            // LiveForm: addition
            if (LiveForm.hasClass(form, 'validate-on-load')) {
              // This is not so nice way, but I don't want to spoil validateForm, validateControl and other methods with another parameter
              LiveForm.setFormProperty(form, 'onLoadValidation', true)
              LiveForm.Nette.validateForm(form)
              LiveForm.setFormProperty(form, 'onLoadValidation', false)
            }

            break
          }
        }

        let submitBtn = null
        let submitEvt = null

        LiveForm.Nette.addEvent(form, 'click', function (e) {
          var target = e.target || e.srcElement
          if (target.form && target.type in { submit: 1, image: 1 }) {
            submitBtn = target.form['nette-submittedBy'] = target
            submitEvt = e
          }
        })

        LiveForm.Nette.addEvent(form, 'submit', function (e) {
          if (LiveForm.forms[form.id].hasError === false) {
            NetteAjax.ajax({ /* off: ['redirect'] */ }, submitBtn || form, submitEvt || e)
            submitBtn = submitEvt = null
          }
          e.preventDefault()
          e.stopPropagation()
          return false
        })
      },

      update (el, binding) {
        console.log('update', el, binding)
      }
    })
  }
}
