/*
 * DOMParser HTML extension
 * 2012-02-02
 *
 * By Eli Grey, http://eligrey.com
 * Public domain.
 * NO WARRANTY EXPRESSED OR IMPLIED. USE AT YOUR OWN RISK.
 */

/* @source https://gist.github.com/1129031 */
/* global document, DOMParser */

(function (DOMParser) {
  const DOMParserProto = DOMParser.prototype
  const realParseFromString = DOMParserProto.parseFromString

  // Firefox/Opera/IE throw errors on unsupported types
  try {
    // WebKit returns null on unsupported types
    if ((new DOMParser()).parseFromString('', 'text/html')) {
      // text/html parsing is natively supported
      return
    }
  } catch (ex) {}

  DOMParserProto.parseFromString = function (markup, type) {
    if (/^\s*text\/html\s*(?:;|$)/i.test(type)) {
      const doc = document.implementation.createHTMLDocument('')
      const docElt = doc.documentElement
      docElt.innerHTML = markup

      const firstElt = docElt.firstElementChild

      if (docElt.childElementCount === 1 && firstElt.localName.toLowerCase() === 'html') {
        doc.replaceChild(firstElt, docElt)
      }

      return doc
    } else {
      return realParseFromString.apply(this, arguments)
    }
  }
}(DOMParser))

export default DOMParser
