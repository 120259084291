import NetteAjax from './NetteAjax'
import InstallAbort from './Ext/Abort'
import InstallConfirm from './Ext/Confirm'
import InstallForm from './Ext/Form'
import InstallInit from './Ext/Init'
// import InstallRedirect from './Ext/Redirect'
import InstallVueRouter from './Ext/VueRouter'
import InstallRender from './Ext/Render'
import InstallUnique from './Ext/Unique'
import InstallValidation from './Ext/Validation'
import InstallCache from './Ext/Cache'

InstallInit(NetteAjax)
InstallAbort(NetteAjax)
InstallUnique(NetteAjax)
InstallValidation(NetteAjax)
InstallConfirm(NetteAjax)
InstallForm(NetteAjax)
// InstallRedirect(NetteAjax)
InstallVueRouter(NetteAjax)
InstallCache(NetteAjax)
InstallRender(NetteAjax)

export default NetteAjax
