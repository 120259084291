import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    component: Layout
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior (to, from, savedPosition) {
    console.log(from, to)
    if (to.path !== from.path) {
      if (to.hash === '') {
        return { x: 0, y: 0 }
      } else {
        // kokoti skroluji na idcko
        const el = document.getElementById(to.hash.substr(1))
        const rect = el.getBoundingClientRect()
        return { x: 0, y: rect.top + document.body.scrollTop }
      }
    }
  }
})

router.afterEach((to, from) => {
  store.dispatch('routeChanged')
})

export default router
