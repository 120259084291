/* eslint-disable */


function awModal(selector)
{
  var modal = document.querySelector(selector);
  if (modal) {
    modal.classList.add('-active');
    document.body.classList.add('modal-open');
  }
}

function awModalClose(selector)
{
  var activeModal = document.querySelector('.modal.-active');
  if (activeModal) {
    document.querySelector('.modal.-active').classList.remove('-active');
    document.body.classList.remove('modal-open');
  }

}


 var closeDetail = function()
{
  var activeJob = document.querySelector('.block-career__job-detail.-active');

  if (activeJob) {

    // activeJob.style.display = 'none';

    activeJob.classList.add('animate__zoomOut');

    var demandWrapper = document.querySelector('.demand-form-wrapper');
    demandWrapper.classList.remove('scale-0');
    //demandWrapper.style.visibility = 'visible';

    activeJob.addEventListener('animationend', () => {
      if (activeJob.classList.contains('-active') && activeJob.classList.contains('animate__zoomOut')) {
        activeJob.classList.remove('-active');
        activeJob.classList.remove('animate__zoomOut');
      }
    });

  }

}

// function closeForm()
// {
//   var activeJob = document.querySelector('.career-demand.-active');

//   if (activeJob) {
//     activeJob.style.display = 'none';
//     activeJob.classList.remove('-active');
//   }

// }

var careerHover = function (event) {
  document.querySelectorAll('.block-career__job').forEach(item => {
    item.addEventListener('click', event => {
      var id = item.getAttribute('data-job');
      var activeJob = document.getElementById('job-' + id)

      activeJob.classList.remove('animate__zoomOut');
      activeJob.classList.remove('animate__zoomInDown');
      activeJob.classList.remove('-active');

      activeJob.classList.add('-active', 'animate__animated', 'animate__fast', 'animate__zoomInDown');
      activeJob.style.display = '';

      var demandWrapper = document.querySelector('.demand-form-wrapper');
      // demandWrapper.style.visibility = 'hidden';
      demandWrapper.classList.remove('d-none');
      demandWrapper.classList.add('scale-0');

      var flashes = document.getElementById('frm-flashes');
      if (flashes)
        flashes.remove();

      dataLayer.push({
        event: 'careerShow',
        eventLabel: item.getAttribute('data-job-name')
      });
    })
  })

};

var jobSign = function (event) {
  document.querySelectorAll('.job-sign').forEach(item => {
    item.addEventListener('click', event => {

      document.getElementById('frm-careerMsp-demandForm-career_id').value = item.getAttribute('data-job');
      closeDetail();

      document.getElementById('career-scroll').scrollIntoView({ 
        behavior: 'smooth'
      });
    })
  })
};



window.addEventListener('load', jobSign, false);
window.addEventListener('load', careerHover, false);

window.addEventListener('load', function () {
  
  document.getElementById('header-arrow').onclick = function () {
      document.getElementById('section-offer').scrollIntoView({
        behavior: 'smooth'
      });
  };

  document.getElementById('logo').onclick = function () {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  function mox (state) {
    if (state) {
      video.controls = 'controls';
      videoArrow.style.display = 'none';
    } else {
      video.controls = '';
      // videoArrow.style.display = '';
    }
  }
  
  function playVideo () {
    mox(true);
    video.play();
    videoArrow.style.display = 'none';
  }
  

  var video = document.getElementById('video');
  var videoArrow = document.getElementById('video-arrow');

  video.addEventListener('playing', function () { mox(false) }, false);
  video.addEventListener('click', function () { mox(true) }, false);
  videoArrow.addEventListener('click', function () { playVideo() }, false);
  video.addEventListener('mouseout', function () { mox(false) }, false);
  video.addEventListener('pause', function () {
    videoArrow.style.display = '';
  }, false);
  
  mox(false);

}, false);

document.addEventListener("keyup", function (event) {
  if (event.key === 'Escape') {
    awModalClose();
  }
});

document.addEventListener('click', function (event) {

  if (event.target.id && event.target.id === 'aw-overlay') {
    awModalClose();
    return;
  }

  if (!(event.target.closest('.block-career__job') || event.target.closest('.block-career__job-detail')) || event.target.classList.contains('close')) {
    //closeForm();
    closeDetail();
  }

  // console.log(event.target.classList.contains('close'));

});


document.addEventListener('click', function (event) {
  if (event.target.id == 'btn-sign') {
    setTimeout(function () {
      if (document.querySelectorAll('.text-danger').length === 0) {
        document.getElementById('btn-sign').innerHTML = 'Odesílám ...';
        document.getElementById('btn-sign').disabled = 'disabled';
        // document.getElementById('btn-sign').style.display = 'none';
      }
    }, 50);
    
  }


});


// document.querySelector('.btn-sign').addEventListener('click', function (event) {
//   console.log('CLICK FORMULAR');
// });