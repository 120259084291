import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import './scss/app.scss'
// import 'bootstrap-vue/src/components/dropdown/index.scss'

import NetteAjax from './nette/NetteAjax'
import NetteForms from './directives/NetteForms'

import Flickity from 'vue-flickity'
import awsliderflickity from './components/awsliderflickity'

import './vanilla'

Vue.use(VueAxios, axios)
Vue.use(NetteAjax.ext('vue-router'), 'snippet--root')
Vue.use(NetteForms)
// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)

Vue.component('Flickity', Flickity)
Vue.component('aw-sliderflickity', awsliderflickity)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#' + NetteAjax.ext('vue-router').rootElementId)
