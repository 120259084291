export default function InstallUnique (NetteAjax) {
  // abort last request if new started
  NetteAjax.ext('unique', {
    start: function (settings) {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel()
      }
      this.cancelTokenSource = settings.cancelTokenSource
    },
    complete: function () {
      this.cancelTokenSource = null
    }
  }, { cancelTokenSource: null })
}
