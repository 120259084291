import Vue from 'vue'
import Vuex from 'vuex'
import menu from './modules/menu'
import cookiepanel from './modules/cookiepanel'
import gallerydetail from './modules/gallerydetail'
import visitorvote from './modules/visitorvote'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: {

  },

  mutations: {

  },

  actions: {
    routeChanged ({ dispatch }) {
      dispatch('menu/closeMenu')
    }
  },

  modules: {
    menu,
    cookiepanel,
    gallerydetail,
    visitorvote
  },

  strict: debug
})
