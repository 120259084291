export default function InstallRender (NetteAjax) {
  NetteAjax.ext('render', {
    success: async function (payload, response, settings) {
      if (payload.presenter) {
        console.time('render')
        console.log('page render', payload.snippets || {}, payload.presenter, { ...payload.storeData, ...(settings.linkData && settings.linkData.storeData ? settings.linkData.storeData : {}) })
        await NetteAjax.ext('vue-router').render(payload.snippets || {}, payload.presenter, { ...(payload.storeData || {}), ...(settings.linkData && settings.linkData.storeData ? settings.linkData.storeData : {}) })
        console.timeEnd('render')
      }
    }
  }, {})
}
