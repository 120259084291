export default function InstallCache (NetteAjax) {
  // cache ajax results
  NetteAjax.ext('cache', {
    before (settings) {
      if (settings.nette && settings.nette.form) return

      if (settings.url in this.data && this.data[settings.url].presenter.template in this.snippets) {
        settings.cachedData = {
          ...this.data[settings.url],
          snippets: this.snippets[this.data[settings.url].presenter.template]
        }
      }
    },
    success (payload, response, settings) {
      if (settings.nette && settings.nette.form) return

      if (payload.presenter && payload.storeData) {
        this.data[settings.url] = {
          presenter: payload.presenter,
          storeData: payload.storeData
        }

        this.presenter[payload.presenter.template] = payload.presenter

        if (payload.snippets && 'template' in payload.presenter) {
          this.snippets[payload.presenter.template] = payload.snippets
        }
      }
    }
  }, {
    snippets: {}, // snippets cache
    data: {}, // data cache
    presenter: {} // presenter data cache
  })
}
