export default function installInit (NetteAjax) {
  NetteAjax.ext('init', {
    load: function (rh) {
      /*
      $('body').off('click.nette', this.linkSelector, rh).on('click.nette', this.linkSelector, rh)
      $(this.formSelector).off('submit.nette', rh).on('submit.nette', rh)
        .off('click.nette', ':image', rh).on('click.nette', ':image', rh)
        .off('click.nette', ':submit', rh).on('click.nette', ':submit', rh)
      $(this.buttonSelector).closest('form')
        .off('click.nette', this.buttonSelector, rh).on('click.nette', this.buttonSelector, rh)
      */
      console.log('NetteAjax init load')
    }
  }, {
    linkSelector: 'a.ajax',
    formSelector: 'form.ajax',
    buttonSelector: 'input.ajax[type="submit"], button.ajax[type="submit"], input.ajax[type="image"]'
  })
}
