import Cookies from 'js-cookie'

if (!window.localStorage.getItem('AW_VISITOR')) {
  window.localStorage.setItem('AW_VISITOR', Cookies.get('AW_VISITOR'))
} else {
  Cookies.set('AW_VISITOR', window.localStorage.getItem('AW_VISITOR'))
}
// initial state
const state = () => ({
  hash: window.localStorage.getItem('AW_VISITOR')
})

// functionName: (state, getters, rootState) => {}
const getters = {
}

// functionName ({ commit, state, dispatch }, param, ...) {}
const actions = {
}

// functionName (state, { param, ... }) {
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
