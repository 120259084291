import Nette from '../../Nette'
import LiveForm from '../../LiveForm'

export default function InstallValidation (NetteAjax) {
  NetteAjax.ext('validation', {
    before: function (settings) {
      if (!settings.nette) return true
      else var analyze = settings.nette
      var e = analyze.e

      var validate = {
        ...this.defaults,
        ...(settings.validate || (() => {
          if (!analyze.ui.getAttribute('data-ajax-validate')) return
          var attr = analyze.ui.getAttribute('data-ajax-validate')
          if (attr === 'false') {
            return {
              keys: false,
              url: false,
              form: false
            }
          } // else if (typeof attr === 'object') return attr
        })() || {})
      }

      var passEvent = false
      if (analyze.ui.getAttribute('data-ajax-pass')) {
        passEvent = analyze.ui.getAttribute('data-ajax-pass') !== 'false'
      }

      if (validate.keys) {
        // thx to @vrana
        var explicitNoAjax = e.button || e.ctrlKey || e.shiftKey || e.altKey || e.metaKey

        if (analyze.form) {
          if (explicitNoAjax && analyze.isSubmit) {
            this.explicitNoAjax = true
            return false
          } else if (analyze.isForm && this.explicitNoAjax) {
            this.explicitNoAjax = false
            return false
          }
        } else if (explicitNoAjax) return false
      }

      if (validate.form && analyze.form) {
        if (analyze.isSubmit || analyze.isImage) {
          analyze.form['nette-submittedBy'] = analyze.ui
        }
        var notValid
        if ((typeof Nette.version === 'undefined' || Nette.version === '2.3')) { // Nette 2.3 and older
          notValid = (analyze.form.onsubmit && analyze.form.onsubmit(e) === false)
        } else { // Nette 2.4 and up
          notValid = ((analyze.form.onsubmit ? analyze.form.triggerHandler('submit') : LiveForm.Nette.validateForm(analyze.form)) === false)
        }
        if (notValid) {
          e.stopImmediatePropagation()
          e.preventDefault()
          return false
        }
      }

      if (validate.url) {
        // thx to @vrana
        var urlToValidate = analyze.form ? settings.url : analyze.ui.getAttribute('href')
        // Check if URL is absolute
        if (/(?:^[a-z][a-z0-9+.-]*:|\/\/)/.test(urlToValidate)) {
          // Parse absolute URL
          var parsedUrl = new URL(urlToValidate)
          if (/:|^#/.test(parsedUrl.pathname + parsedUrl.search + parsedUrl.hash)) return false
        } else {
          if (/:|^#/.test(urlToValidate)) return false
        }
      }

      if (!passEvent) {
        e.stopPropagation()
        e.preventDefault()
      }
      return true
    }
  }, {
    defaults: {
      keys: true,
      url: true,
      form: true
    },
    explicitNoAjax: false
  })
}
