<template>
    <component :is="$snippets[layoutComponent]"/>
</template>

<script>
import NetteAjax from '../nette/NetteAjax'

export default {
  name: 'Layout',
  data () {
    return {
      layoutComponent: NetteAjax.ext('vue-router').rootElementId
    }
  },
  async beforeRouteUpdate (to, from, next) {
    const NetteVueRouter = NetteAjax.ext('vue-router')
    const response = await NetteVueRouter.load(to, ['redirect'])
    // solve redirects?
    if (response && response.redirect) {
      const redirect = NetteVueRouter.urlToRoute(response.redirect)
      if (redirect.path !== to.path) {
        next(redirect)
      } else {
        next(false)
        window.location.href = response.redirect
      }
    } else if (response) {
      next()
    } else {
      next(false)
    }
  },
  created () {
    NetteAjax.ext('vue-router').load(this.$route)
  },
  beforeUpdate () {
  },
  updated () {
    const anchor = this.$router.currentRoute.hash
    this.$nextTick(() => {
      if (anchor) {
        const el = document.getElementById(anchor.substr(1))
        const rect = el.getBoundingClientRect()
        window.scrollTo(0, rect.top + document.body.scrollTop)
      }
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
