import Router from '../../../router'
import DOMParser from '@/model/domparser'
import Vue from 'vue'

export default function InstallVueRouter (NetteAjax) {
  // cache ajax results
  NetteAjax.ext('vue-router', {
    init () {
      const cacheExt = this.ext('cache')
      const url = window.location.pathname + window.location.search
      cacheExt.data[url] = {
        storeData: 'storeData' in window ? window.storeData : {},
        presenter: 'presenter' in window ? window.presenter : {}
      }

      const templateId = cacheExt.data[url].presenter.template
      cacheExt.snippets[templateId] = {}
      cacheExt.snippets[templateId][this.rootElementId] = this.rootElement.innerHTML
      cacheExt.presenter[templateId] = 'presenter' in window ? window.presenter : {}
    },
    before (settings) {
      if (settings.nette && settings.nette.form) return
      const cacheExt = this.ext('cache')

      // try to get data from next link
      if (this.data && this.data.template in cacheExt.snippets) {
        settings.cachedData = {
          storeData: this.data.storeData,
          presenter: cacheExt.presenter[this.data.template],
          snippets: cacheExt.snippets[this.data.template]
        }
      } else if (this.data) {
        settings.linkData = this.data
      }

      this.ui = this.e = this.data = null
    },
    success (data, response, settings) {
      if (data && data.redirect && (settings.off || []).indexOf('redirect') === -1) {
        const redirect = this.urlToRoute(data.redirect)
        if (redirect.path !== Router.currentRoute.path && (!settings.nette || !settings.nette.form)) {
          Router.replace(redirect)
        } else {
          window.location.href = data.redirect
        }
      }
    }
  }, {
    data: null,
    ui: null,
    e: null,
    async load (route) {
      return await NetteAjax.ajax({ url: route.fullPath.substr(0, route.fullPath.length - route.hash.length), off: ['redirect'] }, this.ui, this.e)
    },
    urlToRoute (href) {
      const url = new URL(decodeURIComponent(href))
      const to = url.pathname

      const query = {}
      if (url.search) {
        const searchArray = url.search.substring(1).split('&')
        for (let searchTerm in searchArray) {
          searchTerm = searchArray[searchTerm].split('=')
          query[searchTerm[0]] = searchTerm[1]
        }
      }

      return { path: to, query: query }
    },
    // root element
    rootElement: null,
    // root element
    rootElementId: null,
    // new rendered components parent => children list
    newRenderTree: {},
    // curently rendered components parent => children list
    renderTree: {},
    // snippetId => [startTag, endTag]
    snippetsTags: {},
    // renderer functions
    rendererFunctions: {},
    // presenter data
    $data: {},
    // snippets components
    $snippets: {},
    // controls data
    $controls: {},

    init (rootElementId) {
      this.$data = Vue.observable({})
      this.$controls = Vue.observable({})
      this.$snippets = Vue.observable({})

      this.rootElementId = rootElementId
      // save root element
      this.rootElement = document.getElementById(this.rootElementId)
      // add root component to observable components list
      Vue.set(this.$snippets, this.rootElementId, null)
      // save root tag definition
      this.saveSnippetTag(this.rootElementId, this.rootElement)
    },

    install (Vue, options) {
      this.init(options)
      const self = this

      Vue.mixin({
        beforeCreate () {
          this._presenter = self
        }
      })

      Object.defineProperty(Vue.prototype, '$presenter', {
        get () { return this._presenter.$data }
      })

      Object.defineProperty(Vue.prototype, '$snippets', {
        get () { return this._presenter.$snippets }
      })

      Object.defineProperty(Vue.prototype, '$controls', {
        get () { return this._presenter.$controls }
      })
    },

    // updates data in store
    updateData (data) {
      for (const k in data) {
        if (k !== '$controls') Vue.set(this.$data, k, data[k])
      }
    },

    // replace data when root element rerender
    replaceData (data) {
      for (const k in this.$data) {
        if (k !== '$controls') Vue.delete(this.$data, k)
      }
      this.updateData(data)
    },

    updateControlsData (controls) {
      for (const k in controls) {
        Vue.set(this.$controls, k, controls[k])
      }
    },

    // replace data when root element rerender
    replaceControlsData (controls) {
      for (const k in this.$controls) {
        Vue.delete(this.$controls, k)
      }
      this.updateControlsData(controls)
    },

    async updateSnippets (snippets) {
      for (const k in snippets) {
        Vue.set(this.$snippets, k, await snippets[k])
      }
    },

    _cacheRenderFunctions (presenter, snippets, data) {
      if (presenter && presenter.template) {
        this.rendererFunctions[presenter.template] = {}
      }

      const components = {}
      for (const k in snippets) {
        const componentsDepth = {}
        const snippetHtml = new DOMParser().parseFromString(snippets[k], 'text/html')
        const innerSnippets = snippetHtml.querySelectorAll('[id^="snippet-"]')
        for (let i = 0; i < innerSnippets.length; i++) {
          // save snippet tag
          this.saveSnippetTag(innerSnippets[i].getAttribute('id'), innerSnippets[i])
          // lets find closest snippet parent
          let parent = innerSnippets[i]
          let depth = 0
          do {
            parent = parent.parentNode
            while (parent.tagName !== 'BODY' && (!parent.getAttribute('id') || parent.getAttribute('id').substr(0, 8) !== 'snippet-')) {
              parent = parent.parentNode
            }

            if (depth === 0) {
              if (parent.getAttribute('id') && parent.getAttribute('id').substr(0, 8) === 'snippet-') {
                this.willRenderComponent(parent.getAttribute('id'), innerSnippets[i].getAttribute('id'))
              } else if (parent.tagName === 'BODY') {
                this.willRenderComponent(k, innerSnippets[i].getAttribute('id'))
              }
            }

            depth++
          } while (parent.tagName !== 'BODY')
          componentsDepth[innerSnippets[i].getAttribute('id')] = [i, depth]
        }

        const keysSorted = Object.keys(componentsDepth).sort((a, b) => { return componentsDepth[b][1] - componentsDepth[a][1] })

        keysSorted.forEach(element => {
          console.log('sdafasddfa', element, componentsDepth[element])
          const snippet = innerSnippets[componentsDepth[element][0]]
          if (presenter && presenter.template) {
            this.rendererFunctions[presenter.template][element] = Vue.compile(snippet.outerHTML)
            components[element] = this.createSnippetComponent(element, presenter, this.rendererFunctions[presenter.template][element], data.$controls)
          } else {
            components[element] = this.createSnippetComponent(element, presenter, snippet.outerHTML, data.$controls)
          }
          // replace snippet tag with vue component
          const vueComp = snippetHtml.createElement('component')
          vueComp.setAttribute(':is', '$snippets[\'' + snippet.getAttribute('id') + '\']')
          snippet.parentNode.replaceChild(vueComp, snippet)
        })
        // here should be deleting of prev child components
        this.cleanupSnippetsComponents(k)

        if (presenter && presenter.template) {
          console.log(k, this.snippetsTags)
          this.rendererFunctions[presenter.template][k] = Vue.compile(this.snippetsTags[k][0] + snippetHtml.body.innerHTML + this.snippetsTags[k][1])
          components[k] = this.createSnippetComponent(k, presenter, this.rendererFunctions[presenter.template][k], data.$controls)
        } else {
          components[k] = this.createSnippetComponent(k, presenter, this.snippetsTags[k][0] + snippetHtml.body.innerHTML + this.snippetsTags[k][1], data.$controls)
        }
      }

      return components
    },

    async render (snippets, presenter, data) {
      if (this.rootElementId in snippets) {
        this.replaceData(data)
        this.replaceControlsData(data.$controls || {})
      } else {
        this.updateData(data)
        this.updateControlsData(data.$controls || {})
      }

      let components = {}
      /*
      Fucking cache ---> components can updatest in snippets ...
      if (presenter && presenter.template in this.rendererFunctions) {
        for (const k in this.rendererFunctions[presenter.template]) {
          components[k] = this.createSnippetComponent(k, presenter, this.rendererFunctions[presenter.template][k], data.$controls)
        }
        for (const k in snippets) {
          // here should be deleting of prev child components
          this.cleanupSnippetsComponents(k)
        }
      } else {
      */
      components = this._cacheRenderFunctions(presenter, snippets, data)
      // }

      await this.updateSnippets(components)
      this.renderTree = { ...this.renderTree, ...this.newRenderTree }
      this.newRenderTree = {}
    },

    willRenderComponent (closestParent, el) {
      if (!(closestParent in this.newRenderTree)) {
        this.newRenderTree[closestParent] = []
      }

      if (!(el in this.newRenderTree)) {
        this.newRenderTree[el] = []
      }

      this.newRenderTree[closestParent].push(el)
    },

    cleanupSnippetsComponents (root) {
      if (root in this.renderTree) {
        this.renderTree[root].forEach(child => {
          this.cleanupSnippetsComponents(child)
          if (child in this.$snippets && !(child in this.newRenderTree)) {
            Vue.delete(this.$snippets, child)
          }
        })
      }
    },

    saveSnippetTag (snippetId, element) {
      const elStr = element.outerHTML
      this.snippetsTags[snippetId] = [
        elStr.substr(0, elStr.indexOf('>') + 1),
        '</' + element.nodeName.toLowerCase() + '>'
      ]
    },

    importComponents (controls, template) {
      const components = {}

      for (const k in controls) {
        if ('$component' in controls[k] /* && template.indexOf('<' + controls[k].$el) !== -1 */) {
          components[controls[k].$el] = () => import('@/components/' + controls[k].$component)
        }
      }

      return components
    },

    createComputed () {
      const computed = {}

      for (const k in this.$data) {
        computed[k] = () => {
          return this.$data[k]
        }
      }

      return computed
    },

    async createSnippetComponent (name, presenter, template, controls) {
      const component = {
        name,
        components: {},
        computed: {}
      }

      if (typeof template === 'string') {
        component.template = template
      } else {
        component.render = template.render
        component.staticRenderFns = template.staticRenderFns
      }

      return import('@/views/' + presenter.name + '.' + presenter.action).then((ImportedModule) => {
        return { ...component, ...ImportedModule.default }
      }, () => {
        return component
      }).then((component) => {
        component.components = { ...component.components, ...this.importComponents(controls, template) }
        component.computed = { ...component.computed, ...this.createComputed() }
        return component
      })
    }
  })
}
