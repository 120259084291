// initial state
const state = () => ({
  galleryList: []
})

// functionName: (state, getters, rootState) => {}
const getters = {
}

// functionName ({ commit, state, dispatch }, param, ...) {}
const actions = {
  setGalleryList ({ commit, state, dispatch }, galleryList) {
    commit('setGalleryList', galleryList)
  }
}

// functionName (state, { param, ... }) {
const mutations = {
  setGalleryList (state, galleryList) {
    state.galleryList = galleryList
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
