export default function InstallConfirm (NetteAjax) {
  NetteAjax.ext({
    before: function (settings) {
      if (!settings.nette) {
        return
      }

      const question = settings.nette.ui.getAttribute('data-confirm')
      if (question) {
        return confirm(question)
      }
    }
  })
}
