export default function InstallAbort (NetteAjax) {
  // option to abort by ESC (thx to @vrana)
  NetteAjax.ext('abort', {
    init: function () {
      document.body.addEventListener('keydown', (e) => {
        if (this.cancelTokenSource && (e.keyCode.toString() === '27' && // Esc
        !(e.ctrlKey || e.shiftKey || e.altKey || e.metaKey))
        ) {
          this.cancelTokenSource.cancel()
        }
      })
    },
    start: function (settings) {
      this.cancelTokenSource = settings.cancelTokenSource
    },
    complete: function () {
      this.cancelTokenSource = null
    }
  }, { cancelTokenSource: null })
}
