import Nette from './Nette'

/**
 * Live Form Validation for Nette Forms 2.4
 *
 * @author Robert Pösel, zakrava, Radek Ježdík, MartyIX, David Grudl
 * @version 1.9.0-dev
 * @url https://github.com/Robyer/nette-live-form-validation/
 */
export default new class LiveForm {
  options = {
    // CSS class of control's parent where error/valid class should be added; or "false" to use control directly
    showMessageClassOnParent: 'form-group',

    // CSS class of control's parent where error/valid message should be added (fallback to direct parent if not found); or "false" to use control's direct parent
    messageParentClass: false,

    // CSS class for an invalid control
    controlErrorClass: 'has-error',

    // CSS class for a valid control
    controlValidClass: 'has-success',

    // CSS class for an error message
    messageErrorClass: 'help-block text-danger',

    // control with this CSS class will show error/valid message even when control itself is hidden (useful for controls which are hidden and wrapped into special component)
    enableHiddenMessageClass: 'show-hidden-error',

    // control with this CSS class will have disabled live validation
    disableLiveValidationClass: 'no-live-validation',

    // control with this CSS class will not show valid message
    disableShowValidClass: 'no-show-valid',

    // tag that will hold the error/valid message
    messageTag: 'span',

    // class for tag that will hold the error/valid message
    messageClass: 'control-message',

    // message element id = control id + this postfix
    messageIdPostfix: '_message',

    // show this html before error message itself
    messageErrorPrefix: '<i class="fas fa-exclamation" aria-hidden="true"></i>&nbsp;',

    // show all errors when submitting form; or use "false" to show only first error
    showAllErrors: true,

    // show message when valid
    showValid: false,

    // delay in ms before validating on keyup/keydown; or use "false" to disable it
    wait: false,

    // vertical screen offset in px to scroll after focusing element with error (useful when using fixed navbar menu which may otherwise obscure the element in focus); or use "false" for default behavior
    focusScreenOffsetY: false
  }

  forms = {}

  Nette = null

  constructor () {
    // Allow setting options before loading the script just by creating global LiveFormOptions object with options.
    if (typeof window.LiveFormOptions !== 'undefined') {
      this.setOptions(window.LiveFormOptions)
    }

    this.Nette = new Nette(this)
  }

  setOptions (userOptions) {
    for (var prop in userOptions) {
      if (Object.prototype.hasOwnProperty.call(this.options, prop)) {
        this.options[prop] = userOptions[prop]
      }
    }
  }

  isSpecialKey (k) {
    // http://stackoverflow.com/questions/7770561/jquery-javascript-reject-control-keys-on-keydown-event
    return (k === 20 || /* Caps lock */
      k === 16 || /* Shift */
      k === 9 || /* Tab */
      k === 27 || /* Escape Key */
      k === 17 || /* Control Key */
      k === 91 || /* Windows Command Key */
      k === 19 || /* Pause Break */
      k === 18 || /* Alt Key */
      k === 93 || /* Right Click Point Key */
      (k >= 35 && k <= 40) || /* Home, End, Arrow Keys */
      k === 45 || /* Insert Key */
      (k >= 33 && k <= 34) || /* Page Down, Page Up */
      (k >= 112 && k <= 123) || /* F1 - F12 */
      (k >= 144 && k <= 145)) /* Num Lock, Scroll Lock */
  }

  /**
   * Handlers for all the events that trigger validation
   * YOU CAN CHANGE these handlers (ie. to use jQuery events instead)
   */
  setupHandlers (el) {
    if (this.hasClass(el, this.options.disableLiveValidationClass)) { return }

    // Check if element was already initialized
    if (el.getAttribute('data-lfv-initialized')) { return }

    // Remember we initialized this element so we won't do it again
    el.setAttribute('data-lfv-initialized', 'true')

    var handler = (event) => {
      event = event || window.event
      this.Nette.validateControl(event.target ? event.target : event.srcElement)
    }

    var self = this

    this.Nette.addEvent(el, 'change', handler)
    this.Nette.addEvent(el, 'blur', handler)
    this.Nette.addEvent(el, 'keydown', function (event) {
      if (!self.isSpecialKey(event.which) && (self.options.wait === false || self.options.wait >= 200)) {
        // Hide validation span tag.
        self.removeClass(self.getGroupElement(this), self.options.controlErrorClass)
        self.removeClass(self.getGroupElement(this), self.options.controlValidClass)

        const messageEl = self.getMessageElement(this)

        if (messageEl) {
          messageEl.innerHTML = ''
          messageEl.className = ''
        }

        // Cancel timeout to run validation handler
        if (self.timeout) {
          clearTimeout(self.timeout)
        }
      }
    })
    this.Nette.addEvent(el, 'keyup', function (event) {
      if (self.options.wait !== false) {
        event = event || window.event
        if (event.keyCode !== 9) {
          if (self.timeout) clearTimeout(self.timeout)
          self.timeout = setTimeout(function () {
            handler(event)
          }, self.options.wait)
        }
      }
    })
  };

  processServerErrors (el) {
    var messageEl
    if (!(messageEl = this.getMessageElement(el))) { return }

    var parentEl = this.getMessageParent(el) // This is parent element which contain the error elements

    var errors = []

    // Find existing error elements by class (from server-validation)
    var errorEls = parentEl.getElementsByClassName(this.options.messageErrorClass)
    for (var i = errorEls.length - 1; i > -1; i--) {
      // Don't touch our main message element
      if (errorEls[i] === messageEl) { continue }

      // Remove only direct children
      var errorParent = errorEls[i].parentNode
      if (errorParent === parentEl) {
        errors.push(errorEls[i].outerHTML)
        errorParent.removeChild(errorEls[i])
      }
    }

    // Wrap all server errors into one element
    if (errors.length > 0) {
      messageEl.innerHTML = errors.join('')
    }
  };

  addError (el, message) {
    // Ignore elements with disabled live validation
    if (this.hasClass(el, this.options.disableLiveValidationClass)) { return }

    var groupEl = this.getGroupElement(el)
    this.setFormProperty(el.form, 'hasError', true)
    this.addClass(groupEl, this.options.controlErrorClass)

    if (this.options.showValid) {
      this.removeClass(groupEl, this.options.controlValidClass)
    }

    if (!message) {
      message = '&nbsp;'
    } else {
      message = this.options.messageErrorPrefix + message
    }
    const messageEl = this.getMessageElement(el)

    if (messageEl) {
      messageEl.innerHTML = message
      messageEl.className = messageEl.getAttribute('data-lfv-class') || this.options.messageErrorClass
    }
  };

  removeError (el) {
    var groupEl = this.getGroupElement(el)
    if (this.options.showValid) {
      if (this.showValid(el)) { this.addClass(groupEl, this.options.controlValidClass) } else { this.removeClass(groupEl, this.options.controlValidClass) }
    }

    // We don't want to remove any errors during onLoadValidation
    if (this.getFormProperty(el.form, 'onLoadValidation')) { return }

    this.removeClass(groupEl, this.options.controlErrorClass)

    var id = el.getAttribute('data-lfv-message-id')
    if (id) {
      const messageEl = this.getMessageElement(el)
      if (messageEl) {
        messageEl.innerHTML = ''
        messageEl.className = ''
      }
    }
  };

  showValid (el) {
    if (el.type) {
      var type = el.type.toLowerCase()
      if (type === 'checkbox' || type === 'radio') {
        return false
      }
    }

    var rules = this.Nette.parseJSON(el.getAttribute('data-nette-rules'))
    if (rules.length === 0) {
      return false
    }

    if (this.Nette.getEffectiveValue(el) === '') {
      return false
    }

    if (this.hasClass(el, this.options.disableShowValidClass)) {
      return false
    }

    return true
  };

  getGroupElement (el) {
    if (this.options.showMessageClassOnParent === false) { return el }

    var groupEl = el

    while (!this.hasClass(groupEl, this.options.showMessageClassOnParent)) {
      groupEl = groupEl.parentNode

      if (groupEl === null) {
        return el
      }
    }

    return groupEl
  }

  getMessageId (el) {
    var tmp = el.id + this.options.messageIdPostfix

    // For elements without ID, or multi elements (with same name), we must generate whole ID ourselves
    if (el.name && (!el.id || !el.form.elements[el.name].tagName)) {
      // Strip possible [] from name
      var name = el.name.match(/\[\]$/) ? el.name.match(/(.*)\[\]$/)[1] : el.name
      // Generate new ID based on form ID, element name and messageIdPostfix from options
      tmp = (el.form.id ? el.form.id : 'frm') + '-' + name + this.options.messageIdPostfix
    }

    // We want unique ID which doesn't exist yet
    var id = tmp
    var i = 0
    // data atribut kvuli zabraneni vytvareni dalsich podobnych idecek viz tento cyklus, my nechceme dalsi hlasky, je to kvuli rucnimu renderingu
    // napr v radio listu
    // <RJ, 27.06.2019 12:29:26>
    while (document.getElementById(id) && !document.getElementById(id).getAttribute('data-lfv-manual-renderer')) {
      id = id + '_' + ++i
    }

    return id
  }

  getMessageElement (el) {
    // For multi elements (with same name) work only with first element attributes
    if (el.name && el.name.match(/\[\]$/)) {
      el = el.form.elements[el.name].tagName ? el : el.form.elements[el.name][0]
    }

    // pro hidden inputy negenerujeme zadne error hlasky a nic podobneho, rozdrbe to akorat vzhled <RJ, 10.12.2018 12:11:23>
    // if ($(el).is('[type="hidden"]') || $(el).is('.deactivateinput') || $(el).is('button') || $(el).is('input[type="submit"]')) { return }
    if (el.type.toLowerCase() === 'hidden' || this.hasClass(el, 'deactivateinput') || el.tagName.toLowerCase() === 'button' || el.type.toLowerCase() === 'submit') { return }

    var id = el.getAttribute('data-lfv-message-id')
    if (!id) {
      // ID is not specified yet, let's create a new one
      id = this.getMessageId(el)

      // Remember this id for next use
      el.setAttribute('data-lfv-message-id', id)
    }

    var messageEl = document.getElementById(id)

    if (!messageEl) {
      // Message element doesn't exist, lets create a new one
      messageEl = document.createElement(this.options.messageTag)
      messageEl.id = id
      this.addClass(messageEl, this.options.messageClass)
      if (el.style.display === 'none' && !this.hasClass(el, this.options.enableHiddenMessageClass)) {
        messageEl.style.display = 'none'
      }

      var parentEl = this.getMessageParent(el)

      if (parentEl) {
        try {
          parentEl.insertBefore(messageEl, el.nextSibling)
        } catch (e) {

        }
      }
    } else {

    }

    return messageEl
  };

  getMessageParent (el) {
    var parentEl = el.parentNode
    var parentFound = false

    if (this.options.messageParentClass !== false) {
      parentFound = true
      while (!this.hasClass(parentEl, this.options.messageParentClass)) {
        parentEl = parentEl.parentNode

        if (parentEl === null) {
          // We didn't found wanted parent, so use element's direct parent
          parentEl = el.parentNode
          parentFound = false
          break
        }
      }
    }

    // Don't append error message to radio/checkbox input's label, but along label
    if (el.type) {
      var type = el.type.toLowerCase()
      if ((type === 'checkbox' || type === 'radio') && parentEl.tagName === 'LABEL') {
        parentEl = parentEl.parentNode
      }
    }

    // For multi elements (with same name) use parent's parent as parent (if wanted one is not found)
    if (!parentFound && el.name && !el.form.elements[el.name].tagName) {
      parentEl = parentEl.parentNode
    }

    return parentEl
  }

  addClass (el, className) {
    if (!el.className) {
      el.className = className
    } else if (!this.hasClass(el, className)) {
      el.className += ' ' + className
    }
  };

  hasClass (el, className) {
    if (el.className) { return el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)')) }
    return false
  };

  removeClass (el, className) {
    if (this.hasClass(el, className)) {
      var reg = new RegExp('(\\s|^)' + className + '(\\s|$)')
      var m = el.className.match(reg)
      el.className = el.className.replace(reg, (m[1] === ' ' && m[2] === ' ') ? ' ' : '')
    }
  };

  getFormProperty (form, propertyName) {
    if (form == null || this.forms[form.id] == null) { return false }

    return this.forms[form.id][propertyName]
  };

  setFormProperty (form, propertyName, value) {
    if (form == null) { return }

    if (this.forms[form.id] == null) { this.forms[form.id] = {} }

    this.forms[form.id][propertyName] = value
  };
}()
