// initial state
const state = () => ({
  openMenu: false
})

// functionName: (state, getters, rootState) => {}
const getters = {

}

// functionName ({ commit, state, dispatch }, param, ...) {}
const actions = {
  openMenu ({ commit, state }) {
    commit('openMenu')
  },

  closeMenu ({ commit, state }) {
    commit('closeMenu')
  }
}

// functionName (state, { param, ... }) {
const mutations = {
  openMenu (state) {
    state.openMenu = true
    document.body.classList.add('lock')
  },

  closeMenu (state) {
    state.openMenu = false
    document.body.classList.remove('lock')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
